//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import CountryListTable from '@/modules/country/components/country-list-table.vue';
import { CountryPermissions } from '@/modules/country/country-permissions';

export default {
  name: 'app-country-list-page',

  components: {
    [CountryListTable.name]: CountryListTable,
    // [CountryListToolbar.name]: CountryListToolbar,
    // [CountryListFilter.name]: CountryListFilter,
    // [CountryFilterPage.name]: CountryFilterPage,
  },

  data() {
    return {
      vDialog: false,
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    hasPermissionToCreate() {
      return new CountryPermissions(this.currentUser).create;
    },
  },

  methods: {
    ...mapActions({

    }),
    closeDialog(){
      this.vDialog = false;
    },
    openDialog(){
      this.vDialog = true
    },
  },
};
