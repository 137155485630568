//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import vueI18n from '@/vueI18n'
import moment from 'moment';

import { CountryPermissions } from '@/modules/country/country-permissions';
import { CountryModel } from '@/modules/country/country-model';
const { fields } = CountryModel;

export default {
  name: 'app-country-list-table',

  mounted() {
    this.doFetchFirstPage();
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      filter: '',
      selection:[],
      selectedRow: null,
      selectedId: null,
      modalVisible: false,
      dialogVisible: false,
      columns: [
        {
          name: 'id',
          field: 'id',
          label: 'entities.country.fields.id',
          align: 'center',
        },
        {
          name: 'name',
          field: 'name',
          label: 'entities.country.fields.name',
          align: 'center',
        },
        {
          name: 'iso2',
          field: 'iso2',
          label: 'entities.country.fields.iso2',
          align: 'center',
        },
        {
          name: 'iso3',
          field: 'iso3',
          label: 'entities.country.fields.iso3',
          align: 'center',
        },
        { 
          name: 'action', 
          field: 'action', 
          label: 'common.action', 
          align: 'center', 
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      rows: 'country/list/rows',   
      loading: 'country/list/loading',
      pagination: 'country/list/pagination',
      destroyLoading: 'country/destroy/loading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    hasPermissionToEdit() {
      return new CountryPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new CountryPermissions(this.currentUser).destroy;
    },
    fields() {
      return fields;
    },
    language(){
      return vueI18n.locale
    },
    tablePagination: {
      get: function() {
        return {
          page: this.pagination.page || 1,
          rowsPerPage: this.pagination.limit || 10,
          sortBy: this.pagination.orderBy || 'createdAt',
          descending: this.pagination.sortBy == 'desc',
          // rowsNumber: xx if getting data from a server
        }
      },

      set: function(value) {
        console.log(value);
      },
    },
    pagesNumber() {
      return Math.ceil(this.rows.length / this.tablePagination['rowsPerPage']);
    },
  },

  methods: {
    ...mapActions({
      doFetch: 'country/list/doFetch',
      doDestroy: 'country/destroy/doDestroy',
      doMountTable: 'country/list/doMountTable',
      doChangeSort: 'country/list/doChangeSort',
      doChangePaginationCurrentPage: 'country/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'country/list/doChangePaginationPageSize',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = CountryModel.presenter(row, fieldName);
      return val ? val : 'ـــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = row[fieldName]
      const valueEn = val ? val['en'] : null
      const valueAr = val ? val['ar'] : null
      const value = this.currentLanguageCode == 'ar'
        ? valueAr ? valueAr : valueEn
        : valueEn ? valueEn : valueAr
      return value ? value : 'ـــ'
      // return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterGeoPoint(row, fieldName, key) {
      const val = CountryModel.presenter(row, fieldName);
      return val && val[key] ? val[key] : 'ـــ'; 
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd") : 'ـــ'; 
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },

    onModalOpen(row) {
      this.selectedRow = row;
      this.selectedId = row.id;
      this.modalVisible = true
      this.dialogVisible = true
    },
    onModalClose() {
      this.selectedRow = null;
      this.selectedId = null;
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.doDestroy(id);
        this.onModalClose();
      } catch (error) {
        // no
      }
    },

    async doFetchFirstPage() {
      return this.doFetch({
        filter: null,
        orderBy: 'id',
        pagination: {
          sortBy: 'desc',
          limit: 10,
        },
      });
    },
    async doFetchPrevPage() {
      const firstDocument = this.rows[0]
      return this.doFetch({
        filter: null,
        orderBy: 'id',
        pagination: {
          sortBy: 'desc',
          limit: 10,
          doc: firstDocument,
          action: 'prev'
        },
        keepPagination: true,
      });
    },
    async doFetchNextPage() {
      const lastDocument = this.rows[this.rows.length - 1]
      return this.doFetch({
        filter: null,
        orderBy: 'id',
        pagination: {
          sortBy: 'desc',
          limit: 10,
          doc: lastDocument,
          action: 'next'
        },
        keepPagination: true,
      });
    },
  },
};
