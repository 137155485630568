import { render, staticRenderFns } from "./country-list-table.vue?vue&type=template&id=22fafb2b&"
import script from "./country-list-table.vue?vue&type=script&lang=js&"
export * from "./country-list-table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QTable,QInnerLoading,QSpinnerIos,QInput,QIcon,QTr,QTh,QTd,QBtn,QTooltip,QImg,QDialog,QCard} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QInnerLoading,QSpinnerIos,QInput,QIcon,QTr,QTh,QTd,QBtn,QTooltip,QImg,QDialog,QCard})
